<template lang="pug">
  .reviews
    nuxt-img.decoration(
      lazy="true"
      quality="80"
      v-if="decoration"
      src="/images/landing/planet.webp"
    )
    .lp-container
      ReviewsSlider.reviews__slider(
        :slidesPerGroup="3"
      )
</template>

<script>
import ReviewsSlider from '@/components/pages/switch-to-rss/reviews/index'

export default {
  components: {
    ReviewsSlider
  },

  props: {
    decoration: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.reviews {
  position: relative;
  margin-bottom: 80px;

  &:last-child {
    margin-bottom: 140px;
  }

  .lp-container {
    z-index: 3;
    position: relative;
  }

  &__title {
    margin: 0;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    line-height: 150%;
    margin-bottom: $gap * 4;

    @include display-less(tablet) {
      width: 100%;
      font-size: 22px;
      text-align: center;
      margin-bottom: $gap;
    }
  }

  &__slider {
    padding-bottom: 0;
    margin-bottom: $gap * 1.5;

    ::v-deep .slider__wrapper {
      @include display-less(tablet) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    ::v-deep .slider__title {
      display: block;
      position: relative;
      transform: translateX(-32px);

      @include display-less(big) {
        transform: translateX(0);
      }
    }

    ::v-deep .swiper {
      &-button {
        &-prev {
          @include display-less(tablet) {
            left: 0;
          }
        }
        &-next {
          @include display-less(tablet) {
            right: 0;
          }
        }
      }
    }
  }
}

.decoration {
  z-index: 1;
  top: -1600px;
  right: 50%;
  width: 566px;
  height: 566px;
  max-width: 566px;
  position: absolute;
  margin-right: 360px;

  @include display-less(desktop) {
    top: 103px;
    right: 100px;
    width: 458px;
    height: 458px;
    margin-right: 0;
    max-width: 458px;
  }
}
</style>
